import React, { Component } from 'react'
import { MaterialSvgIcon as SvgIcon } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'

const translateKeyframes = {
  '0%': {
    transform: 'rotate(0deg)'
  },

  '100%': {
    transform: 'rotate(360deg)'
  }
}
const styles = StyleSheet.create({
  rotate: {
    display: 'block',
    animationName: translateKeyframes,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  }
})

const Eau = props => (
  <SvgIcon {...props} viewBox="0 0 100 170">
    <g>
      <g transform="translate(50 45)">
        <g className={props.hover ? css(styles.rotate) : undefined}>
          <g transform="translate(-50 -45)">
            <path
              d="M86.224,28.194 C79.593,14.003 65.197,4.833 49.545,4.833 C43.24,4.833 37.204,6.243 31.597,9.023 C31.178,9.229 31.011,9.731 31.215,10.145 C31.42,10.559 31.922,10.728 32.341,10.523 C37.716,7.858 43.5,6.508 49.545,6.508 C64.555,6.508 78.356,15.298 84.708,28.902 C84.904,29.321 85.397,29.502 85.815,29.307 C86.234,29.111 86.42,28.613 86.224,28.194"
              fill="#6ADAFB"
            />
            <path
              d="M89.516,51.554 C89.842,49.495 90,47.387 90,45.287 C90,41.513 89.479,37.775 88.456,34.178 C88.326,33.734 87.87,33.476 87.424,33.603 C86.978,33.73 86.717,34.192 86.847,34.637 C87.824,38.084 88.326,41.669 88.326,45.287 C88.326,47.301 88.168,49.322 87.861,51.297 C87.796,51.754 88.103,52.182 88.559,52.254 C89.014,52.324 89.442,52.012 89.516,51.554"
              fill="#6ADAFB"
            />
            <path
              d="M85.666,63.522 C86.364,62.14 86.996,60.701 87.526,59.247 C87.684,58.814 87.461,58.333 87.033,58.173 C86.596,58.014 86.113,58.236 85.955,58.67 C85.443,60.063 84.848,61.441 84.169,62.765 C83.964,63.179 84.132,63.682 84.541,63.891 C84.959,64.099 85.462,63.934 85.666,63.522"
              fill="#6ADAFB"
            />
            <path
              d="M9.119,43.863 C9.1,44.337 9.091,44.817 9.091,45.287 C9.091,46.714 9.165,48.151 9.314,49.56 C9.36,50.02 9.77,50.354 10.235,50.305 C10.69,50.257 11.025,49.845 10.979,49.386 C10.839,48.034 10.765,46.655 10.765,45.287 C10.765,44.835 10.774,44.376 10.793,43.923 C10.802,43.461 10.439,43.073 9.983,43.056 C9.518,43.04 9.128,43.402 9.119,43.863"
              fill="#6ADAFB"
            />
            <path
              d="M22.194,15.479 C16.214,20.973 12.057,28.03 10.188,35.888 C10.086,36.337 10.356,36.789 10.811,36.896 C11.258,37.002 11.713,36.724 11.816,36.275 C13.61,28.744 17.591,21.979 23.329,16.712 C23.664,16.4 23.692,15.871 23.375,15.529 C23.068,15.189 22.538,15.167 22.194,15.479"
              fill="#6ADAFB"
            />
          </g>
        </g>
      </g>
      <path
        d="M14.671,45.287 C14.671,64.548 30.285,80.162 49.545,80.162 C68.805,80.162 84.42,64.548 84.42,45.287 C84.42,26.027 68.805,10.413 49.545,10.413 C30.285,10.413 14.671,26.027 14.671,45.287"
        fill="#6ADAFB"
      />
      <path d="M47.034,78.767 L52.056,78.767 L52.056,170 L47.034,170 z" fill="#6ADAFB" />
      <path
        d="M49.545,80.162 C50.392,80.162 51.229,80.13 52.056,80.073 L52.056,89.649 L47.034,89.649 L47.034,80.073 C47.862,80.13 48.699,80.162 49.545,80.162"
        fill="#6ADAFB"
      />
      <path
        d="M61.235,49.326 C60.894,47.325 60.128,45.494 59.361,43.834 C57.062,38.767 54.294,34.168 51.569,29.91 L51.228,29.314 C50.76,28.59 50.206,28.207 49.568,28.207 C48.631,28.207 48.035,29.058 47.865,29.314 C47.865,29.314 47.865,29.314 47.865,29.356 L47.268,30.293 C46.204,31.996 45.097,33.785 44.032,35.573 C42.372,38.383 40.285,42.13 38.752,46.133 C38.241,47.453 37.773,48.773 37.73,50.221 C37.56,53.968 38.923,57.119 41.733,59.588 C43.905,61.504 46.672,62.569 49.525,62.569 L49.525,62.569 C53.698,62.569 57.488,60.44 59.659,56.821 C61.107,54.436 61.661,51.881 61.235,49.326 z M58.297,56.012 C56.423,59.162 53.144,60.993 49.525,60.993 C47.056,60.993 44.628,60.099 42.755,58.439 C40.328,56.31 39.136,53.542 39.263,50.306 C39.306,49.114 39.689,47.964 40.2,46.729 C41.69,42.812 43.734,39.15 45.352,36.382 C46.374,34.636 47.481,32.848 48.588,31.145 L49.142,30.251 C49.142,30.208 49.185,30.208 49.185,30.165 C49.27,30.038 49.44,29.825 49.525,29.782 C49.525,29.782 49.653,29.825 49.866,30.165 L50.206,30.719 C52.846,34.892 55.614,39.448 57.871,44.43 C58.595,46.048 59.319,47.751 59.617,49.539 C60.042,51.754 59.574,53.925 58.297,56.012 z M50.292,56.948 C50.292,57.374 49.951,57.715 49.525,57.715 C45.65,57.715 42.542,54.564 42.542,50.732 C42.542,50.306 42.883,49.965 43.308,49.965 C43.734,49.965 44.075,50.306 44.075,50.732 C44.118,53.712 46.545,56.139 49.525,56.139 C49.951,56.139 50.292,56.523 50.292,56.948 z"
        fill="#FFFFFF"
      />
    </g>
  </SvgIcon>
)

export default Eau
