import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MaterialSvgIcon as SvgIcon } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import chroma from 'chroma-js'
import { Material } from 'inno-components/build/Icons'
import Config from '../Config'

const Icon = props => (
  <SvgIcon {...props} viewBox={`0 0 100 ${props.height}`}>
    <g>
      <g transform="translate(50 45)">
        <g
          className={
            props.hover ? css(props.styles.rotate) : css(props.styles.circle)
          }
          fill={props.fill}
        >
          <g transform="translate(-50 -45)">
            <path
              d="M86.5,27.8c-6.6-14-20.7-23-36.1-22.9c-6.2,0-12.1,1.4-17.6,4.2c-0.4,0.2-0.6,0.7-0.4,1.1
					c0.2,0.4,0.7,0.6,1.1,0.4c5.3-2.7,10.9-4,16.8-4c14.7-0.1,28.3,8.6,34.6,22c0.2,0.4,0.7,0.6,1.1,0.4
					C86.5,28.7,86.7,28.2,86.5,27.8"
            />
            <path
              d="M89.8,50.8c0.3-2,0.5-4.1,0.4-6.2c0-3.7-0.5-7.4-1.6-11c-0.1-0.4-0.6-0.7-1-0.6c-0.4,0.1-0.7,0.6-0.6,1
					c1,3.4,1.5,6.9,1.5,10.5c0,2-0.1,4-0.4,5.9c-0.1,0.5,0.2,0.9,0.7,0.9C89.3,51.6,89.7,51.3,89.8,50.8"
            />
            <path
              d="M86.1,62.7c0.7-1.4,1.3-2.8,1.8-4.2c0.2-0.4-0.1-0.9-0.5-1.1c-0.4-0.2-0.9,0.1-1.1,0.5
					c-0.5,1.4-1.1,2.7-1.7,4c-0.2,0.4,0,0.9,0.4,1.1C85.4,63.2,85.9,63.1,86.1,62.7"
            />
            <path
              d="M11,43.6c0,0.5,0,0.9,0,1.4c0,1.4,0.1,2.8,0.2,4.2c0,0.5,0.5,0.8,0.9,0.7c0.4,0,0.8-0.5,0.7-0.9
					c-0.1-1.3-0.2-2.7-0.2-4c0-0.4,0-0.9,0-1.3c0-0.5-0.4-0.8-0.8-0.9C11.3,42.8,11,43.1,11,43.6"
            />
            <path
              d="M23.7,15.5c-5.8,5.4-9.9,12.4-11.7,20.2c-0.1,0.4,0.2,0.9,0.6,1c0.4,0.1,0.9-0.2,1-0.6
					c1.7-7.4,5.6-14.1,11.2-19.4c0.3-0.3,0.4-0.8,0-1.2C24.5,15.2,24,15.2,23.7,15.5"
            />
          </g>
        </g>
      </g>
      <g fill={props.fill}>
        <path d="M85.4,45.3c0,19.3-15.6,34.9-34.9,34.9S15.7,64.5,15.7,45.3c0-19.3,15.6-34.9,34.9-34.9S85.4,26,85.4,45.3" />
        <path d="M48,78.8h5v231.9h-5V78.8z" />
        <path d="M50.5,80.2c-0.8,0-1.7,0-2.5-0.1v9.6h5v-9.6C52.2,80.1,51.4,80.2,50.5,80.2" />
      </g>
      <svg x="35" y="30">
        <g className={props.hover ? 'animated  bounceIn' : undefined}>
          {' '}
          {props.children}
        </g>
      </svg>
    </g>
  </SvgIcon>
)

class IconSection extends Component {
  state = {
    hover: false,
  }
  onMouseOver = () => {
    this.setState({
      hover: true,
    })
  }
  onMouseLeave = () => {
    this.setState({
      hover: false,
    })
  }
  render() {
    const rotateKeyframes = {
      '0%': {
        transform: 'rotate(0deg)',
        opacity: 1,
      },

      '100%': {
        transform: 'rotate(360deg)',
        opacity: 1,
      },
    }

    const styles = StyleSheet.create({
      rotate: {
        display: 'block',
        animationName: rotateKeyframes,
        animationDuration: '1s',
        animationIterationCount: 'infinite',
      },

      container: {
        minHeight: '300px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      },
      iconTitle: {
        textTransform: 'uppercase',
        fontWeight: 800,
        padding: '0.5em',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      },
      iconTitleHover: {
        color: this.props.fill,
        ':hover': {
          color: '#fff',
        },
        ':before': {
          background: this.props.fill,
        },
      },
      iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '180px',
      },
      image: {
        height: 'auto',
        width: '100px',
      },
      circle: {
        opacity: 0,
        transition: 'all 0.5s',
      },
    })
    return (
      <div className={css(styles.iconContainer)}>
        <h3
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          onClick={this.props.onClick}
          className={`${css(
            styles.iconTitle,
            styles.iconTitleHover
          )} hvr-sweep-to-right`}
        >
          {this.props.direction === 'up' ? (
            <Material.MdArrowUpward />
          ) : (
            <Material.MdArrowDownward />
          )}
          <span className={this.state.hover ? 'animated  bounceIn' : undefined}>
            {this.props.title}
          </span>
        </h3>
        <Icon
          height={this.props.height}
          className={css(styles.image)}
          fill={this.props.fill}
          hover={this.state.hover}
          styles={styles}
        >
          {this.props.children}
        </Icon>
      </div>
    )
  }
}

IconSection.propTypes = {
  title: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  direction: PropTypes.string,
}

IconSection.defaultProps = {
  direction: 'up',
}

export default IconSection
