import React from 'react'
import { compose, pure } from 'recompose'
import { graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import Depoussierage from '../../components/methode-raypath/Depoussierage'

const DepoussieragePage = ({ data, t }) => {
  const images = {
    image1: data.image1.childImageSharp,
    image2: data.image2.childImageSharp,
    image3: data.image3.childImageSharp,
    image4: data.image4.childImageSharp,
    image5: data.image5.childImageSharp,
    image6: data.image6.childImageSharp
  }

  return (
    <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathDepoussierage">
      <Depoussierage images={images} />
    </SecondaryLayout>
  )
}

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('depoussierage')
)

export default echance(DepoussieragePage)

export const pageQuery = graphql`
  query DepoussierageImageQuery {
    image1: file(relativePath: { eq: "methode/depoussierage-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image2: file(relativePath: { eq: "methode/depoussierage-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image3: file(relativePath: { eq: "methode/depoussierage-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image4: file(relativePath: { eq: "methode/depoussierage-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image5: file(relativePath: { eq: "methode/depoussierage-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image6: file(relativePath: { eq: "methode/depoussierage-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
  }
`
