import React, { Component } from 'react'
import Img from 'gatsby-image'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { StaticQuery, graphql } from 'gatsby'
import {
  Section, Paper, ImageBasic, MenuHorizontal, MenuItem, MediaQueries
} from 'inno-components'
import { Material } from 'inno-components/build/Icons'
import cn from 'classnames'
import Classes from '../../styles/classes'

import Layout from './Layout'
import Config from '../../Config'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

import Languages from './sidebar/Languages'

const styles = StyleSheet.create({
  menu: {
    alignItems: 'center',
    backgroundColor: Config.colors.primary,
    borderRadius: 0,
    padding: '3px',
    zIndex: 9
  },
  logo: {
    height: 'auto',
    width: '150px'
  },
  link: {
    display: 'block',
    color: '#FFF',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '1.2em',
    cursor: 'pointer',
    transition: 'color 0.5s',
    margin: '1em 0.5em',
    ':hover': {
      color: Config.colors.secondary
    }
  },
  active: {
    fontWeight: 'bold',
    color: Config.colors.secondary
  },
  section: {
    backgroundColor: Config.colors.thirdary
  },
  title: {
    fontSize: '1.5em',
    lineHeight: '1.3em',
    color: '#fff',
    textTransform: 'uppercase',
    [MediaQueries.small]: {
      fontSize: '2em',
      lineHeight: '1.5em'
    }
  },
  logoContainer: {
    display: 'block',
    padding: '1em 2em',
    width: '200px',
    [MediaQueries.small]: {
      padding: '1em 4em',
      width: '100%'
    }
  },
  icon: {
    color: '#FFF'
  }
})

const LinkComponent = ({ link, active }) => (
  <Link
    page={pagesInfos.ComponentIndex}
    className={css(styles.link, active && styles.active)}
    query={`#${link.name}`}
  >
    {link.label}
  </Link>
)

const SecondaryLayout = ({
  t, page, subtitle, children
}) => (
  <StaticQuery
    query={graphql`
      query SecondaryLayoutImageQuery {
        logo: file(relativePath: { eq: "home/logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 239) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const links = [
        MenuItem({
          anchor: 'accueil',
          name: 'accueil',
          label: t('accueil'),
          LinkComponent
        }),
        MenuItem({
          anchor: 'methode-raypath',
          name: 'methode-raypath',
          label: t('methode-raypath'),
          LinkComponent
        }),
        MenuItem({
          anchor: 'galerie-video',
          name: 'galerie-video',
          label: t('galerie-video'),
          LinkComponent
        }),
        MenuItem({
          name: 'notre-entreprise',
          anchor: 'notre-entreprise',
          label: t('notre-entreprise'),
          LinkComponent
        }),
        MenuItem({
          name: 'nos-references',
          anchor: 'nos-references',
          label: t('nos-references'),
          LinkComponent
        }),
        MenuItem({
          name: 'contact',
          anchor: 'contact',
          label: t('contact'),
          LinkComponent
        })
      ]

      return (
        <Layout>
          <MenuHorizontal
            links={links}
            activeItemNames={['methode-raypath']}
            LeftComponent={(
              <Link page={pagesInfos.ComponentIndex}>
                <div className={css(styles.logoContainer)}>
                  <Img fluid={data.logo.childImageSharp.fluid} className={css(styles.logo)} />
                </div>
              </Link>
)}
            RightComponent={<Languages page={page} />}
            className={css(styles.menu)}
            bgOverlayColor="rgba(40, 59, 112,0.95)"
            IconCloseComponent={<Material.MdClose className={css(styles.icon)} />}
            IconOpenComponent={<Material.MdMenu className={css(styles.icon)} />}
          />
          <Section className={css(Classes.section, styles.section)}>
            <h1 className={css(styles.title)}>{subtitle}</h1>
          </Section>
          {children}
        </Layout>
      )
    }}
  />
)

export default withNamespaces('menu')(SecondaryLayout)
