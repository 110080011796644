import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import { Grid, Section, Button } from 'inno-components'
import cn from 'classnames'
import Eau from '../../icons/Eau'
import Dechets from '../../icons/Dechets'
import Pollution from '../../icons/Pollution'

import Config from '../../Config'
import Classes from '../../styles/classes'

import IconSection from '../../icons/IconSection'

import { Material, Typicons, FontAwesome } from 'inno-components/build/Icons'

const styles = StyleSheet.create({
  container: {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  iconTitle: {
    textTransform: 'uppercase',
    fontWeight: 800,
    padding: '0.5em',
    cursor: 'pointer',
  },
  iconTitlePollution: {
    color: '#2C3F68',
    ':hover': {
      color: '#fff',
    },
    ':before': {
      background: '#2C3F68',
    },
  },
  iconTitleDechets: {
    color: '#3C9546',
    ':hover': {
      color: '#fff',
    },
    ':before': {
      background: '#3C9546',
    },
  },
  iconTitleEau: {
    color: '#6FDBF9',
    ':hover': {
      color: '#fff',
    },
    ':before': {
      background: '#6FDBF9',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '180px',
  },
  image: {
    height: 'auto',
    width: '100px',
  },
  bold: {
    fontWeight: 'bold',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.3em',
    color: '#FFFFFF',
  },
  button: {
    display: 'block',
    width: '100%',
    height: 'auto',
    padding: 0,
  },
})

const ButtonImage = ({ image, onClick }) => (
  <Button className={cn(css(styles.button), 'btn25')} onClick={onClick}>
    <Img className={css(styles.button)} fluid={image.fluid} />

    <div className="ovrly" />
    <div className="buttons">
      <Material.MdZoomIn className="fa fa-search" />
    </div>
  </Button>
)

export default ButtonImage
