const config = {
  url: 'http://www.raypathinternational.com',
  name: 'raypath',
  head: {
    image: 'https://www.innobelge.be/images/Logo.jpg',
  },
  facebook: {
    prefix:
      'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# business: http://ogp.me/ns/business#"',
    type: 'business.business',
  },
  colors: {
    primary: '#1E2B52',
    secondary: '#32A93E',
    thirdary: '#28CAFC',
    quaternary: '#DFEEF3',
    quinary: '#FAFAFA',
  },
  langs: {
    default: 'fr',
    locales: ['en', 'fr'],
  },
  fonts: {
    primary: {
      name: "'Raleway', sans-serif",
      url: 'https://fonts.googleapis.com/css?family=Oswald:400,700',
    },
    secondary: {
      name: "'Gloria Hallelujah', sans-serif",
      url: 'https://fonts.googleapis.com/css?family=Montserrat:400,700',
    },
  },
}

export default config
