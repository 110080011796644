import React from 'react'

import { StyleSheet, css } from 'aphrodite'

import { Breakpoints, Button, Flags } from 'inno-components'

import Link from '../../Link'
import pagesInfos from '../../../config/i18n/pagesInfos'
import i18next from '../../../config/i18n/context'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    borderRadius: '50%',
    border: 'solid 2px #eee'
  },
  button: {
    padding: 0,
    width: '36px',
    lineHeight: '36px',
    height: '36px',
    minHeight: '36px',
    minWidth: '36px',
    borderRadius: '50%'
  }
})

const getLanguage = () => i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng)

const Languages = ({ page }) => {
  const lang = getLanguage()
  return (
    <div className={css(styles.container)}>
      {lang !== 'fr' && (
        <Link page={page ? pagesInfos[page] : pagesInfos.ComponentIndex} lang="fr">
          <Button className={css(styles.button)}>
            <Flags.FR className={css(styles.icon)} />
          </Button>
        </Link>
      )}
      {lang !== 'en' && (
        <Link page={page ? pagesInfos[page] : pagesInfos.ComponentIndex} lang="en">
          <Button className={css(styles.button)}>
            <Flags.EN className={css(styles.icon)} />
          </Button>
        </Link>
      )}
      {lang !== 'nl' && (
        <Link page={page ? pagesInfos[page] : pagesInfos.ComponentIndex} lang="nl">
          <Button className={css(styles.button)}>
            <Flags.NL className={css(styles.icon)} />
          </Button>
        </Link>
      )}
    </div>
  )
}

export default Languages
