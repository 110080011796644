import { MediaQueries } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Config from '../Config'

const styles = StyleSheet.create({
  a: {
    textDecoration: 'none'
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    color: Config.colors.primary
  },
  section: {
    padding: '1.5em 1.5em',
    [MediaQueries.small]: {
      padding: '2em 4em'
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  h2: {
    fontSize: '1.4em',
    lineHeight: '1.3em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    color: Config.colors.primary,
    [MediaQueries.small]: {
      fontSize: '1.5em',
      lineHeight: '1.4em'
    }
  },
  h3: {
    fontSize: '1.2em',
    fontWeight: 600,
    marginBottom: '2em',
    color: Config.colors.primary
  },
  h4: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '2em',
    color: Config.colors.thirdary
  },
  headline: {
    fontFamily: Config.fonts.secondary.name,
    fontSize: '1.4em',
    lineHeight: '1.7em',
    margin: '2em 0.5em',
    textAlign: 'center',
    [MediaQueries.small]: {
      margin: '3em 4em'
    }
  },
  paragraph: {
    lineHeight: '26px',
    marginBottom: '1.5em'
  },
  highlight: {
    fontSize: '1.3em',
    margin: '3em 0',
    display: 'block',
    textAlign: 'center'
  },
  tableCellHead: {
    backgroundColor: Config.colors.primary,
    color: '#fff',
    fontWeight: 'bold'
  },
  tableCaption: {
    textAlign: 'center',
    marginTop: '1.5em',
    mariginBottom: '4em',
    fontStyle: 'italic',
    fontSize: '0.8em',
    color: '#777'
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
    [MediaQueries.small]: {
      padding: '2.5em 1em'
    }
  },
  image: {
    width: '100%',
    maxWidth: '250px',
    borderRadius: '0.5em'
  },
  primaryButton: {
    display: 'inline-block',
    padding: '1em',
    color: Config.colors.primary,
    textDecoration: 'none',
    border: `solid 2px ${Config.colors.primary}`
  }
})

export default styles
