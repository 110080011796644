import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { I18nextProvider } from 'react-i18next'

import { MuiThemeProvider, CssBaseline, Favicon } from 'inno-components'

import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'
import i18n from '../../config/i18n/context'

const Layout = ({ children }) => (
  <I18nextProvider i18n={i18n}>
    <CssBaseline />
    <Helmet>
      <html lang={i18n.language || window.localStorage.i18nextLng} />
      <meta name="google-site-verification" content="h8gwhX6Dt2W1ApPV-qn1wf1RaaQ-HIMT2-38n3spk3E" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://storage.googleapis.com/innobelge-websites.appspot.com/commons/styles/hover.css"
      />
    </Helmet>
    <div style={{ maxWidth: '1920px' }}>{children}</div>
  </I18nextProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
