import React, { Component } from 'react'
import { fadeIn, bounceInUp } from 'react-animations'
import { MaterialSvgIcon as SvgIcon } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'

const translateKeyframes = {
  '0%': {
    transform: 'rotate(0deg)'
  },

  '100%': {
    transform: 'rotate(360deg)'
  }
}
const styles = StyleSheet.create({
  rotate: {
    display: 'block',
    animationName: translateKeyframes,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear'
  },
  fade: {
    animationName: bounceInUp,
    animationDuration: '1s'
  }
})

const Eau = props => (
  <SvgIcon {...props} viewBox="0 0 100 185">
    <g>
      <g transform="translate(51 41)">
        <g className={props.hover ? css(styles.rotate) : undefined}>
          <g transform="translate(-51 -41)">
            <path
              d="M69.209,75.656 C69.915,75.881 69.678,75.684 70.017,76.107 C70.228,76.518 70.068,77.023 69.656,77.235 C67.205,78.499 64.617,79.513 61.964,80.247 C61.518,80.372 61.057,80.111 60.933,79.664 C60.81,79.22 61.072,78.758 61.516,78.635 C64.06,77.93 66.541,76.958 68.889,75.747 L69.209,75.656 z"
              fill="#389642"
            />
            <path
              d="M88.39,53.814 L88.719,53.862 C89.157,54.019 89.38,54.498 89.222,54.933 C86.932,61.312 83.023,67.068 77.919,71.581 C77.573,71.887 77.042,71.854 76.737,71.507 C76.431,71.161 76.463,70.632 76.81,70.326 C81.704,66 85.451,60.481 87.648,54.368 C87.928,53.903 87.719,54.13 88.39,53.814 z"
              fill="#389642"
            />
            <path
              d="M11.537,42.654 C11.998,42.629 12.392,42.983 12.418,43.444 C12.515,45.184 12.73,46.93 13.058,48.633 C13.145,49.086 12.848,49.526 12.394,49.613 C11.94,49.701 11.501,49.403 11.414,48.949 C11.072,47.173 10.848,45.352 10.746,43.537 C10.721,43.076 11.075,42.68 11.537,42.654 z"
              fill="#389642"
            />
            <path
              d="M14.984,24.24 L15.308,24.313 C15.73,24.502 15.918,24.997 15.729,25.419 C14.25,28.719 13.245,32.21 12.739,35.795 C12.675,36.253 12.252,36.571 11.793,36.507 C11.336,36.443 11.017,36.02 11.082,35.561 C11.609,31.821 12.658,28.178 14.201,24.734 C14.517,24.293 14.291,24.503 14.984,24.24 z"
              fill="#389642"
            />
            <path
              d="M86.456,22.566 C87.16,22.798 86.924,22.598 87.26,23.024 C88.366,25.212 89.277,27.513 89.966,29.862 C90.096,30.305 89.845,30.77 89.398,30.901 C88.952,31.03 88.487,30.776 88.357,30.332 C87.699,28.081 86.827,25.876 85.766,23.779 C85.558,23.367 85.723,22.863 86.136,22.655 L86.456,22.566 z"
              fill="#389642"
            />
            <path
              d="M34.367,4.504 C35.063,4.756 34.835,4.55 35.157,4.986 C35.352,5.405 35.172,5.904 34.753,6.099 C27.507,9.481 21.556,14.935 17.545,21.869 C17.314,22.268 16.801,22.406 16.401,22.174 C16.002,21.942 15.865,21.43 16.096,21.03 C20.28,13.798 26.486,8.111 34.044,4.582 L34.367,4.504 z"
              fill="#389642"
            />
            <path
              d="M75.341,8.919 L75.64,9.065 C77.543,10.515 79.328,12.144 80.948,13.908 C81.26,14.249 81.237,14.779 80.896,15.091 C80.557,15.404 80.027,15.38 79.714,15.04 C78.162,13.349 76.449,11.786 74.625,10.396 C74.257,10.116 74.186,9.591 74.466,9.223 C74.874,8.865 74.607,9.019 75.341,8.919 z"
              fill="#389642"
            />
          </g>
        </g>
      </g>
      <path
        d="M86.013,41.257 C86.013,60.518 70.4,76.132 51.139,76.132 C31.878,76.132 16.264,60.518 16.264,41.257 C16.264,21.997 31.878,6.383 51.139,6.383 C70.4,6.383 86.013,21.997 86.013,41.257"
        fill="#389642"
      />

      <path d="M48.628,74.737 L53.65,74.737 L53.65,185.5 L48.628,185.5 z" fill="#389642" />

      <path
        d="M51.139,76.132 C50.294,76.132 49.456,76.1 48.628,76.043 L48.628,85.619 L53.65,85.619 L53.65,76.043 C52.82,76.1 51.983,76.132 51.139,76.132"
        fill="#389642"
      />
      <path
        d="M59.347,52.143 C59.097,53.798 57.663,55.015 55.99,54.991 C54.787,54.993 53.672,54.361 53.056,53.328 L51.635,53.328 L48.535,53.328 L48.535,55.142 L45.828,55.142 L45.828,53.328 L45.641,53.328 C45.641,53.328 45.45,51.402 45.233,49.225 L37.652,57.138 L36.331,55.818 L45.006,46.775 L44.724,43.932 C44.432,40.998 44.119,37.883 43.872,35.363 L35.872,26.658 L37.198,25.357 L42.299,30.887 L42.299,30.383 L43.474,30.383 C43.575,29.687 43.927,29.319 44.583,28.871 L44.83,28.699 L44.855,28.699 C45.036,28.6 45.225,28.516 45.419,28.447 L45.419,27.62 L48.131,27.62 L48.131,27.928 C48.55,27.888 48.988,27.857 49.452,27.842 L49.452,27.167 L54.432,27.167 L54.432,27.968 C55.53,28.061 56.609,28.307 57.638,28.699 L57.638,28.699 L57.865,28.795 C57.993,28.85 58.116,28.916 58.233,28.991 C58.53,28.788 58.881,28.681 59.241,28.684 C60.089,28.676 60.827,29.262 61.01,30.09 L65.582,25.317 L66.872,26.683 L60.824,32.994 L60.824,36.336 L58.833,36.336 C58.55,39.894 58.198,44.401 57.93,47.859 L65.27,55.823 L63.974,57.149 z M55.99,50.807 C55.498,50.856 55.133,51.286 55.165,51.779 C55.197,52.273 55.614,52.652 56.108,52.637 C56.602,52.623 56.995,52.219 56.998,51.725 C56.989,51.468 56.877,51.227 56.687,51.054 C56.497,50.881 56.246,50.792 55.99,50.807 z M51.65,51.629 L52.607,51.629 C52.686,49.837 54.171,48.43 55.965,48.448 L51.07,43.151 L46.811,47.597 L47.209,51.629 z M56.358,46.135 C56.585,43.191 56.862,39.768 57.089,36.865 L52.381,41.805 z M45.772,37.414 C46.014,39.874 46.276,42.637 46.539,45.137 L49.78,41.759 z M51.07,40.439 L52.97,38.453 L49.271,38.453 L49.271,36.018 L55.319,36.018 L56.917,34.36 L56.917,32.616 L45.324,32.616 L45.49,34.355 z M59.226,34.773 L59.226,34.647 L59.105,34.773 z M57.285,30.368 L57.22,30.337 L57.124,30.302 L56.882,30.201 C56.608,30.091 56.327,30.002 56.04,29.934 C55.509,29.806 54.97,29.712 54.427,29.652 L54.427,29.894 L49.447,29.894 L49.447,29.506 C48.943,29.506 48.519,29.556 48.126,29.596 L48.126,30.368 z M45.414,30.368 L45.414,30.277 L45.293,30.368 z"
        fill="#FFFFFF"
      />
    </g>
  </SvgIcon>
)

export default Eau
