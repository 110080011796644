module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: 'home',
    path: {
      fr: '/',
      en: '/en',
      nl: '/nl'
    }
  },
  /** **********************************************
    Pie
  ************************************************ */
  ComponentMethodeRaypathAccreditation: {
    id: 'ComponentMethodeRaypathAccreditation',
    namespace: 'accreditations',
    path: {
      fr: '/methode-raypath/accreditations',
      en: '/en/the-raypath-system/accreditations',
      nl: '/nl/de-raypath-methode/referenties'
    }
  },
  ComponentMethodeRaypathComposition: {
    id: 'ComponentMethodeRaypathComposition',
    namespace: 'composition',
    path: {
      fr: '/methode-raypath/composition',
      en: '/en/the-raypath-system/composition',
      nl: '/nl/de-raypath-methode/samenstelling'
    }
  },
  ComponentMethodeRaypathDureeDeVie: {
    id: 'ComponentMethodeRaypathDureeDeVie',
    namespace: 'duree-de-vie',
    path: {
      fr: '/methode-raypath/duree-de-vie',
      en: '/en/the-raypath-system/lifetime',
      nl: '/nl/de-raypath-methode/levensduur'
    }
  },
  ComponentMethodeRaypathGarantieEtProcessus: {
    id: 'ComponentMethodeRaypathGarantieEtProcessus',
    namespace: 'garantie-et-processus-de-recyclage',
    path: {
      fr: '/methode-raypath/garantie-et-processus-de-recyclage',
      en: '/en/the-raypath-system/warranty-and-recycling',
      nl: '/nl/de-raypath-methode/garanties-en-recyclageproces'
    }
  },
  ComponentMethodeRaypathRegleDutilisation: {
    id: 'ComponentMethodeRaypathRegleDutilisation',
    namespace: 'regle-d-utilisation',
    path: {
      fr: '/methode-raypath/regle-d-utilisation',
      en: '/en/the-raypath-system/usage-and-maintenance-instructions',
      nl: '/en/the-raypath-system/regels-voor-gebruik-en-onderhoud'
    }
  },
  /** **********************************************
    Assets
  ************************************************ */
  ComponentAssetsEconomiquementIntelligent: {
    id: 'ComponentAssetsEconomiquementIntelligent',
    namespace: 'economiquement-intelligent',
    path: {
      fr: '/economiquement-intellegent',
      en: '/en/economically-intelligent',
      nl: '/nl/economisch-intelligent'
    }
  },
  ComponentAssetsEnvironnementalementResponsable: {
    id: 'ComponentAssetsEnvironnementalementResponsable',
    namespace: 'environnementalement-responsable',
    path: {
      fr: '/environnementalement-responsable',
      en: '/en/environmentally-responsible',
      nl: '/nl/milieubewust'
    }
  },
  ComponentAssetsSocialementRespectueux: {
    id: 'ComponentAssetsSocialementRespectueux',
    namespace: 'socialement-respectueux',
    path: {
      fr: '/socialement-respectueux',
      en: '/en/socially-responsible',
      nl: '/nl/sociaal-respectvol'
    }
  },
  /** **********************************************
    Méthode Raypath
  ************************************************ */
  ComponentMethodeRaypath: {
    id: 'ComponentMethodeRaypath',
    namespace: 'home',
    path: {
      fr: '/methode-raypath',
      en: '/en/the-raypath-system',
      nl: '/nl/de-raypath-methode'
    }
  },
  ComponentMethodeRaypathDepoussierage: {
    id: 'ComponentMethodeRaypathDepoussierage',
    namespace: 'depoussierage',
    path: {
      fr: '/methode-raypath/depoussierage',
      en: '/en/the-raypath-system/dusting',
      nl: '/nl/de-raypath-methode/afstoffen'
    }
  },
  ComponentMethodeRaypathSurfacesAcceptantPeuEau: {
    id: 'ComponentMethodeRaypathSurfacesAcceptantPeuEau',
    namespace: 'surfaces-acceptant-peu-d-eau',
    path: {
      fr: '/methode-raypath/surfaces-acceptant-peu-d-eau',
      en: '/en/the-raypath-system/water-damageable-surfaces',
      nl: '/nl/de-raypath-methode/oppervlakken-die-weinig-water-verdragen'
    }
  },
  ComponentMethodeRaypathSurfacesAcceptantBeaucoupEau: {
    id: 'ComponentMethodeRaypathSurfacesAcceptantBeaucoupEau',
    namespace: 'surfaces-acceptant-beaucoup-d-eau',
    path: {
      fr: '/methode-raypath/surfaces-acceptant-beaucoup-d-eau',
      en: '/en/the-raypath-system/water-safe-surfaces',
      nl: '/nl/de-raypath-methode/oppervlakken-die-veel-water-verdragen'
    }
  },
  ComponentMethodeRaypathSechageEtBrillance: {
    id: 'ComponentMethodeRaypathSechageEtBrillance',
    namespace: 'sechage-et-brillance',
    path: {
      fr: '/methode-raypath/sechage-et-brillance',
      en: '/en/the-raypath-system/drying-and-shining',
      nl: '/nl/de-raypath-methode/drogen-en-opblinken'
    }
  },
  ComponentMethodeRaypathAccessoiresDivers: {
    id: 'ComponentMethodeRaypathAccessoiresDivers',
    namespace: 'accessoires-divers',
    path: {
      fr: '/methode-raypath/accessoires-divers',
      en: '/en/the-raypath-system/various-accessories',
      nl: '/nl/de-raypath-methode/diverse-accessoires'
    }
  },
  /** **********************************************
    Références
  ************************************************ */
  ComponentReferencesPresseInternet: {
    id: 'ComponentReferencesPresseInternet',
    namespace: 'home',
    path: {
      fr: '/presse-internet',
      en: '/en/internet-press',
      nl: '/nl/internetpers'
    }
  },
  ComponentReferencesPresseMagazine: {
    id: 'ComponentReferencesPresseMagazine',
    namespace: 'home',
    path: {
      fr: '/presse-magazine',
      en: '/en/magazines-press',
      nl: '/nl/tijdschriften'
    }
  },
  ComponentReferencesPresseRadio: {
    id: 'ComponentReferencesPresseRadio',
    namespace: 'home',
    path: {
      fr: '/presse-radio',
      en: '/en/radio-press',
      nl: '/nl/radiopers'
    }
  }
}
